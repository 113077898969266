<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Surat Pesanan (PO) Apotek'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'pharmacy-purchase-orders'"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/component/medicine/PurchaseOrderForm.vue";

export default {
  name: "Add",

  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {
          date: new Date().toISOString().substr(0, 10),
          po_no: "",
          vendor_id: "",
          vendor_name: "",
          items: "",
          discount: "",
          ppn: "",
          // items: `<figure class="table"><table><tbody><tr><td><p style="text-align:center;"><strong>No</strong></p></td><td><p style="text-align:center;"><strong>Items</strong></p></td><td><p style="text-align:center;"><strong>Quantity</strong></p></td><td><p style="text-align:center;"><strong>Price/Case</strong></p><p style="text-align:center;"><strong>(IDR)</strong></p></td><td><p style="text-align:center;"><strong>Total</strong></p><p style="text-align:center;"><strong>Prices</strong></p></td></tr><tr><td>1</td><td>Terufusion Blood ADM Set – TBU800L11 9 ( Terumo )</td><td>6 box</td><td>1,500,000</td><td><p style="text-align:right;"><strong>9,000,000</strong></p></td></tr><tr><td>&nbsp;</td><td><p style="text-align:center;"><strong>SUB TOTAL 1</strong></p></td><td>&nbsp;</td><td>&nbsp;</td><td><p style="text-align:right;"><strong>9,000,000</strong></p></td></tr><tr><td>&nbsp;</td><td><p style="text-align:center;"><strong>Diskon 40 %</strong></p></td><td>&nbsp;</td><td>&nbsp;</td><td><p style="text-align:right;"><strong>3,600,000</strong></p></td></tr><tr><td>&nbsp;</td><td><p style="text-align:center;"><strong>SUB TOTAL 2</strong></p></td><td>&nbsp;</td><td>&nbsp;</td><td><p style="text-align:right;"><strong>5,400,000</strong></p></td></tr><tr><td>&nbsp;</td><td><p style="text-align:center;"><strong>PPn 10%</strong></p></td><td>&nbsp;</td><td>&nbsp;</td><td><p style="text-align:right;"><strong>540,000</strong></p></td></tr><tr><td>&nbsp;</td><td><p style="text-align:center;"><strong>TOTAL</strong></p></td><td>&nbsp;</td><td>&nbsp;</td><td><p style="text-align:right;"><strong>5,940,000</strong></p></td></tr></tbody></table></figure>`,
          opening_sentence: `Sehubungan dengan surat penawaran harga (quotation) Nama Supplier, maka kami ingin memesan produk sebagai berikut :`,
          closing_sentence: "Demikian pesanan ini kami sampaikan, Mohon kiranya dapat di proses dengan segera. Atas perhatian dan kerjasamanya kami ucapkan terimakasih",
      },
      
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Surat Pesanan (PO) Apotek", route: "/purchase-orders-pharmacy" },
      { title: "Tambah" },
    ]);
  },
};
</script>

<style>
</style>